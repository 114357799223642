import React from "react";
import QuickBooksLogo from "../images/logos/quickbooks.svg";
import NetsuiteLogo from "../images/logos/netsuite.svg";
import SageLogo from "../images/logos/sage intacct.svg";
import XeroLogo from "../images/logos/xero 1.svg";
import ADPLogo from "../images/logos/adp.svg";
import WorkdayLogo from "../images/logos/workday.svg";
import RipplingLogo from "../images/logos/rippling.svg";
import JustworksLogo from "../images/logos/justworks.svg";
import SalesforceLogo from "../images/logos/salesforce.svg";
import HubspotLogo from "../images/logos/hubspot.svg";
import StripeLogo from "../images/logos/stripe.svg";
import MondayLogo from "../images/logos/monday.svg";
import SnowflakeLogo from "../images/logos/snowflake.svg";
import PowerBILogo from "../images/logos/power bi.svg";
import GoogleSheetsLogo from "../images/logos/google sheets.svg";

function LogoBox({ logo, name, height = "40", width = "40" }) {
  return (
    <div className="flex flex-col justify-center items-center p-8 text-center bg-gray-50 rounded hover:bg-gray-100">
      {logo && <img src={logo} width={width} height={height} />}
      <p>{name}</p>
    </div>
  );
}

export default function IntegrationLogos() {
  return (
    <section class="bg-white">
      <div class="py-8 px-4 mx-auto max-w-screen-xl">
        <h2 class="mb-4 text-3xl font-extrabold tracking-tight leading-tight text-center text-gray-900 md:text-4xl">
          Integrate all your data sources
        </h2>
        <p class="mb-4 text-base font-normal text-center text-gray-900 md:text-xl sm:px-14 md:px-24 lg:px-56 xl:px-72">
          Cashboard supports{" "}
          <span className="font-bold">200+ integrations</span>, from ERPs and
          HRIS systems to CRMs, data warehouses, project management tools and
          spreadsheets.
        </p>

        <div class="mt-6 space-y-4 md:grid md:grid-cols-4 md:gap-4 xl:gap-8 md:space-y-0 md:mb-8 md:mt-12">
          <LogoBox logo={QuickBooksLogo} name="Quickbooks" />
          <LogoBox logo={NetsuiteLogo} name="Netsuite" />
          <LogoBox logo={SageLogo} name="Sage Intacct" width="auto" />
          <LogoBox logo={XeroLogo} name="Xero" />

          <LogoBox logo={ADPLogo} name="ADP" width="auto" />
          <LogoBox logo={WorkdayLogo} name="Workday" />
          <LogoBox logo={RipplingLogo} name="Rippling" />
          <LogoBox logo={JustworksLogo} name="Justworks" />

          <LogoBox logo={SalesforceLogo} name="Salesforce" />
          <LogoBox logo={HubspotLogo} name="Hubspot" />
          <LogoBox logo={StripeLogo} name="Stripe" />
          <LogoBox logo={MondayLogo} name="Monday" />

          <LogoBox logo={SnowflakeLogo} name="Snowflake" />
          <LogoBox logo={PowerBILogo} name="Power BI" />
          <LogoBox logo={GoogleSheetsLogo} name="Google Sheets" width="29" />
          <LogoBox name="and 200+ other integrations" />
        </div>
      </div>
    </section>
  );
}
