import React from "react";
import { RequestADemo } from "./smallerComponents";

export default function CallToAction() {
  return (
    <section class="bg-white">
      <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
          <h2 class="mb-6 text-4xl tracking-tight font-extrabold leading-tight text-gray-900">
            Get started today.
          </h2>
          <RequestADemo />
        </div>
      </div>
    </section>
  );
}
