import React from "react";
import CashboardHeroImage from "../images/hero/Cashboard - Hero Image.png";
import YCombinatorLogo from "../images/hero/ycombinator.svg";
import TTVLogo from "../images/hero/ttv.png";
import SOC2Badge from "../images/hero/soc2badge.png";
import { RequestADemo } from "./smallerComponents";

export default function Hero() {
  return (
    <section class="bg-white">
      <div class="max-w-screen-xl px-4 pt-8 mx-auto text-center lg:pt-16 lg:px-12">
        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl">
          Great CFOs run data-driven businesses on Cashboard
        </h1>
        <p class="mb-8 font-normal text-gray-900 md:text-lg lg:text-xl sm:px-16 xl:px-48">
          Meet the analytics platform built for finance teams. Accelerate your
          daily and monthly internal reporting, securely share data with leaders
          across your company, and tighten feedback loops like never before.
        </p>
        <RequestADemo />
        <img
          class="mx-auto mb-5 lg:mb-8 border border-gray-200 rounded-lg shadow-xl z-1"
          src={CashboardHeroImage}
          alt="dashboard overview"
        />
      </div>
      <div class="pt-48 lg:pb-16 pb-8 -mt-48 bg-[#F8F5F2] sm:pt-80 sm:-mt-80 z-2">
        <div class="px-4 mx-auto text-center md:max-w-screen-md lg:max-w-screen-lg lg:px-36">
          <div class="flex flex-wrap items-center justify-center mt-8 text-gray-500 sm:justify-between">
            <p className="text-grey-500">Backed by</p>
            <a href="https://www.ycombinator.com/" class="">
              <img
                src={YCombinatorLogo}
                alt="Y-Combinator Logo"
                height={44}
                width={203}
              />
            </a>
            <a href="https://ttvcapital.com/" class="">
              <img src={TTVLogo} alt="TTV Logo" height={44} width={44} />
            </a>
            <hr className="border h-[44px] hidden md:block" />
            <a
              href="https://www.aicpa-cima.com/resources/landing/system-and-organization-controls-soc-suite-of-services?"
              className="flex flex-row items-center justify-center sm:justify-between space-x-8 pt-3 md:pt-0"
            >
              <p className="text-grey-500">SOC II, Type 2 Compliant</p>
              <img
                src={SOC2Badge}
                alt="SOC2 Type 1 Badge"
                height={44}
                width={44}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
