import React from "react";
import ArrowRightGreen from "../images/arrow-right-outline-green.svg";
import TeamBoardsImage from "../images/content4/Team Boards.png";
import SensitiveDataImage from "../images/content4/Sensitive Data.png";
import CommentingImage from "../images/content4/Commenting.png";

export default function ContentBlock4() {
  return (
    <section class="bg-white">
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="text-center text-gray-900">
          <h2 class="mb-4 mx-auto max-w-screen-md text-4xl tracking-tight font-extrabold text-gray-900 lg:text-5xl">
            Share data with your teams, seamlessly and securely
          </h2>
          <form
            action="https://ukl5axh9451.typeform.com/to/olB0lPsO"
            className="flex items-center justify-center"
          >
            <button
              type="submit"
              className="flex flex-row w-fit items-center px-5 py-3 bg-white text-lg font-medium text-[#2C915E] sm:px-10 mt-2"
            >
              Book a demo
              <img
                src={ArrowRightGreen}
                alt="Arrow Right"
                height={20}
                width={20}
                className="ml-2"
              />
            </button>
          </form>
        </div>
        <div class="grid gap-6 mt-12 lg:mt-14 lg:gap-12 md:grid-cols-3">
          <div class="lg:flex lg:mb-2 flex-col mb-0">
            <img
              class="mr-4 w-auto h-36 h-auto rounded-lg"
              src={TeamBoardsImage}
              alt="Team Boards Image"
            />
            <div>
              <h3 class="text-xl font-bold mt-4 mb-2.5 text-gray-900">
                Create accountability with boards for every team
              </h3>
              <p class="text-gray-500">
                With Team Boards, you can easily create custom-filtered boards
                with their own data and visualizations.
              </p>
            </div>
          </div>
          <div class="lg:flex lg:mb-2 flex-col mb-0">
            <img
              class="mr-4 w-auto h-36 h-auto rounded-lg"
              src={SensitiveDataImage}
              alt="Sensitive Data Image"
            />
            <div>
              <h3 class="text-xl font-bold mt-4 mb-2.5 text-gray-900">
                Hide sensitive data
              </h3>
              <p class="text-gray-500">
                Granularly set permissions on which data you want teammates to
                view.
              </p>
            </div>
          </div>
          <div class="lg:flex lg:mb-2 flex-col mb-0">
            <img
              class="mr-4 w-auto h-36 h-auto rounded-lg"
              src={CommentingImage}
              alt="Commenting Image"
            />
            <div>
              <h3 class="text-xl font-bold mt-4 mb-2.5 text-gray-900">
                Have conversations where the numbers are
              </h3>
              <p class="text-gray-500">
                Keep feedback cycles tight with comments and notifications.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
