import CashboardLogo from "../images/logo.svg";
import { React, useState } from "react";

export default function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav class="bg-white fixed w-full z-20 top-0 start-0 border-b border-gray-200 py-6">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4">
        <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
          <img
            src={CashboardLogo}
            height={29}
            width={159}
            class="h-8"
            alt="Cashboard Logo"
          />
        </a>
        <div class="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          <div className="hidden md:flex flex-row space-x-0">
            <form action="https://app.cashboard.finance/">
              <button className="flex flex-row items-center w-fit px-5 py-[10px] bg-white text-sm font-medium text-[#111928]">
                Log in
              </button>
            </form>
            <form action="https://ukl5axh9451.typeform.com/to/olB0lPsO">
              <button
                type="submit"
                className="flex flex-row items-center w-fit rounded-md border border-transparent px-5 py-[10px] bg-cb-prime text-sm font-medium text-white shadow hover:bg-prime-hover focus:outline-none focus:ring-2 focus:ring-cb-prime focus:ring-offset-2"
              >
                Sign up
              </button>
            </form>
          </div>
          <button
            onClick={toggleMobileMenu}
            data-collapse-toggle="navbar-sticky"
            type="button"
            class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-sticky"
            aria-expanded={isMobileMenuOpen ? "true" : "false"}
          >
            <span class="sr-only">Toggle main menu</span>
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        {isMobileMenuOpen && (
          <div
            class="items-center justify-between md:hidden w-full md:w-auto md:order-1"
            id="navbar-sticky"
          >
            <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
              <li>
                <a
                  href="https://app.cashboard.finance/"
                  class="block py-2 px-3 text-gray-900 rounded md:bg-transparent md:text-blue-700 md:p-0"
                  aria-current="page"
                >
                  Log in
                </a>
              </li>
              <li>
                <a
                  href="https://ukl5axh9451.typeform.com/to/olB0lPsO"
                  class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                >
                  Sign up
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
}
