import React from "react";
import ArrowRight from "../images/arrow-right.svg";
import ArrowRightGreen from "../images/arrow-right-outline-green.svg";

export function RequestADemo() {
  return (
    <div class="flex mb-8 lg:mb-16 sm:flex-row justify-center">
      <form action="https://ukl5axh9451.typeform.com/to/olB0lPsO">
        <button
          type="submit"
          className="flex flex-row items-center w-fit rounded-md border border-transparent px-5 py-[10px] bg-cb-prime text-base font-medium text-white shadow hover:bg-prime-hover focus:outline-none focus:ring-2 focus:ring-cb-prime focus:ring-offset-2"
        >
          Request a demo
          <img
            src={ArrowRight}
            alt="Arrow Right"
            height={16}
            width={16}
            className="ml-2"
          />
        </button>
      </form>
    </div>
  );
}

export function BookADemo() {
  return (
    <form
      action="https://ukl5axh9451.typeform.com/to/olB0lPsO"
      className="flex items-center justify-center"
    >
      <button
        type="submit"
        className="flex flex-row w-fit items-center px-5 py-3 bg-white text-lg font-medium text-[#2C915E] sm:px-10 mt-2"
      >
        Book a demo
        <img
          src={ArrowRightGreen}
          alt="Arrow Right"
          height={20}
          width={20}
          className="ml-2"
        />
      </button>
    </form>
  );
}
