import "./App.css";
import Header from "./components/header";
import Hero from "./components/hero";
import ContentBlock1 from "./components/content1";
import ContentBlock2 from "./components/content2";
import ContentBlock3 from "./components/content3";
import ContentBlock4 from "./components/content4";
import IntegrationLogos from "./components/logos";
import CallToAction from "./components/cta";
import Footer from "./components/footer";

export default function Splash() {
  return (
    <div className="bg-white">
      <div className="h-[89px]"></div>
      <Header />
      <Hero />
      <ContentBlock1 />
      <ContentBlock2 />
      <ContentBlock3 />
      <ContentBlock4 />
      <IntegrationLogos />
      <CallToAction />
      <Footer />
    </div>
  );
}
