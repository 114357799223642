import React from "react";
import MarketingBVAImage from "../images/content1/Marketing BvA.png";
import PoductLineDailyNumbersImage from "../images/content1/Product Line Daily Numbers.png";

export default function ContentBlock1() {
  return (
    <section class="bg-[#F8F5F2]">
      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div class="font-normal text-gray-500 sm:text-lg">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
            Get your teams all the data they need — without having to hire
          </h2>
          <p class="mb-4">
            Leading CFOs spent the past decade building custom analytics
            platforms that combine financials and KPIs in one place. But this
            came at a price: huge analytics teams and 12-month buildouts.
          </p>
          <p>
            With Cashboard, you’ll get world-class analytics and the dashboards
            your teams rely on, without having to hire or wait.
          </p>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-8">
          <img
            class="w-full rounded-lg"
            src={MarketingBVAImage}
            alt="Marketing BvA Image"
          />
          <img
            class="mt-4 w-full lg:mt-10 rounded-lg"
            src={PoductLineDailyNumbersImage}
            alt="Product Line Daily Numbers Image"
          />
        </div>
      </div>
    </section>
  );
}
