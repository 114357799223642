import React from "react";
import LinkedInLogo from "../images/logos/linkedin.svg";

export default function Footer() {
  return (
    <footer class="p-4 py-8 bg-white md:p-8 lg:p-10">
      <div class="mx-auto max-w-screen-xl text-center">
        <div class="sm:items-center sm:justify-between sm:flex">
          <span class="block text-sm text-gray-500">
            © 2024 Cashboard. All rights reserved.
          </span>
          <div class="flex justify-center mt-4 space-x-6 sm:mt-0">
            <a
              href="https://www.linkedin.com/company/cashboardhq/"
              class="text-gray-500 hover:text-gray-900"
            >
              <img src={LinkedInLogo} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
