import React from "react";
import { BookADemo } from "./smallerComponents";
import FinancialFormatsImage from "../images/content3/Financial Formats.png";
import VisualizationsImage from "../images/content3/Visualizations Crop.png";
import BudgetsAndForecastsImage from "../images/content3/Budgets & Forecasts.png";

export default function ContentBlock3() {
  return (
    <section class="bg-white">
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="text-center text-gray-900">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 lg:text-5xl">
            Tell any story with powerful building blocks
          </h2>
          <BookADemo />
        </div>
        <div class="grid gap-6 mt-12 lg:mt-14 lg:gap-12 md:grid-cols-3">
          <div class="lg:flex lg:mb-2 flex-col mb-0">
            <img
              class="mr-4 w-auto h-36 h-auto rounded-lg"
              src={FinancialFormatsImage}
              alt="Financial Formats Image"
            />
            <div>
              <h3 class="text-xl font-bold mt-4 mb-2.5 text-gray-900">
                The financial formats you love with the KPIs leaders need
              </h3>
              <p class="text-gray-500">
                Create BvAs, Income Statements, any custom tables with any data
                or custom calculations you can imagine.
              </p>
            </div>
          </div>
          <div class="lg:flex lg:mb-2 flex-col mb-0">
            <img
              class="mr-4 w-auto h-36 h-auto rounded-lg"
              src={VisualizationsImage}
              alt="Visualizations Image"
            />
            <div>
              <h3 class="text-xl font-bold mt-4 mb-2.5 text-gray-900">
                Beautiful tables and visualizations
              </h3>
              <p class="text-gray-500">
                Interactive charts, tables, and maps bring your data to life.
              </p>
            </div>
          </div>
          <div class="lg:flex lg:mb-2 flex-col mb-0">
            <img
              class="mr-4 w-auto h-36 h-auto rounded-lg"
              src={BudgetsAndForecastsImage}
              alt="Budgets and Forecasts Image"
            />
            <div>
              <h3 class="text-xl font-bold mt-4 mb-2.5 text-gray-900">
                Budgets and forecasts
              </h3>
              <p class="text-gray-500">
                Instantly toggle between any of the budgets and forecasts you’ve
                synced with Cashboard.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
