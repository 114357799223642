import React from "react";
import { BookADemo } from "./smallerComponents";
import AllYourDataImage from "../images/content2/All Your Data.png";
import DataMappingImage from "../images/content2/Data Mapping Crop.png";
import DrillInImage from "../images/content2/Drill In On Anything.png";

export default function ContentBlock2() {
  return (
    <section class="bg-white">
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="text-center text-gray-900">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 lg:text-5xl">
            All your data in one place
          </h2>
          <BookADemo />
        </div>
        <div class="grid gap-6 mt-12 lg:mt-14 lg:gap-12 md:grid-cols-3">
          <div class="lg:flex lg:mb-2 flex-col mb-0">
            <img
              class="mr-4 w-auto h-36 h-auto rounded-lg"
              src={AllYourDataImage}
              alt="All Your Data Image"
            />
            <div>
              <h3 class="text-xl font-bold mt-4 mb-2.5 text-gray-900">
                Live data from all your systems
              </h3>
              <p class="text-gray-500">
                Live data constantly flows in from all your business systems via
                native API integrations, ensuring that what you see is accurate
                and up to date.
              </p>
            </div>
          </div>
          <div class="lg:flex lg:mb-2 flex-col mb-0">
            <img
              class="mr-4 w-auto h-36 h-auto rounded-lg"
              src={DataMappingImage}
              alt="Data Mapping Image"
            />
            <div>
              <h3 class="text-xl font-bold mt-4 mb-2.5 text-gray-900">
                Automated data mapping
              </h3>
              <p class="text-gray-500">
                Cashboard keeps the mappings between your data sources up to
                date, allowing you to spin up filtered views of your data in
                seconds.
              </p>
            </div>
          </div>
          <div class="lg:flex lg:mb-2 flex-col mb-0">
            <img
              class="mr-4 w-auto h-36 h-auto rounded-lg"
              src={DrillInImage}
              alt="Drill In On Anything Image"
            />
            <div>
              <h3 class="text-xl font-bold mt-4 mb-2.5 text-gray-900">
                Drill in on anything
              </h3>
              <p class="text-gray-500">
                Drill down into detailed data from any system with one click.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
